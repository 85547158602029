class Storage {

    /*
        :: Local Storage
    */
    static storeItem(key, val) {
        if (this.localStorageIsSupported()) {
            localStorage.setItem(key, val);
        }
    }

    static getItem(key) {
        if (this.localStorageIsSupported()) {
            return localStorage.getItem(key);
        }
    }

    /*
        :: Cookies
    */
    static setCookie(cname, cvalue, exdays) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        const expires = "expires="+d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    static getCookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for(var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    /*
        :: Helpers
    */
    static localStorageIsSupported() {
        let testKey = 'test', storage = window.sessionStorage;
        try {
            storage.setItem(testKey, '1');
            storage.removeItem(testKey);
            return true;
        } catch (error) {
            return false;
        }
    }

}

/*
    :: ADA Text Resizer
    --------------------
    The html gets a base font size of 62.5% to set a 1:10 font ratio
        EX: font-size: 20px; = font-size: 2rem;
    On increase or decrease, the html will get different classes

*/
const _storageKey = 'text_sizer_value';

class TextSizeChanger {
  static main() {
    window.addEventListener('load', function () {
      jQuery('.text-size-button.increase').on('click', (e) => {
        e.preventDefault();

        const html = jQuery(('html'));

        if (html.hasClass("text-large")) {
          html.removeClass("text-large");
          html.addClass("text-larger");
          Storage.storeItem(_storageKey, "text-larger");
        }
        else if (html.hasClass("text-larger")) {
          html.removeClass("text-larger");
          html.addClass("text-largest");
          Storage.storeItem(_storageKey, "text-largest");
        }
        else if (!html.hasClass("text-largest")) {
          html.addClass("text-large");
          Storage.storeItem(_storageKey, "text-large");
        }
      });

      jQuery('.text-size-button.decrease').on('click', (e) => {
        e.preventDefault();

        const html = $(('html'));

        if (html.hasClass("text-large")) {
          html.removeClass("text-large");
          localStorage.removeItem(_storageKey);
        }
        else if (html.hasClass("text-larger")) {
          html.removeClass("text-larger");
          html.addClass("text-large");

          Storage.storeItem(_storageKey, "text-large");
        }
        else if (html.hasClass("text-largest")) {
          html.removeClass("text-largest");
          html.addClass("text-larger");
          Storage.storeItem(_storageKey, "text-larger");
        }
      });
    });
    this.getSetting();
  }

  /*
      :: Helpers
  */

  static getSetting(key) {
    let stored = Storage.getItem(_storageKey);
    if (stored) {
      jQuery(('html')).addClass(stored);
    }
  }
}

/*
    :: Core ST Front End boilerplate Javascript Build
*/

class StCore {

    static main(options) {
        if (options.initialize.TextResizer) {
            TextSizeChanger.main();
        }
    }

}

class ScrollToTop {

    static main() {
        
    	// Scroll To Top
    	$('.scrollToTop').on('click', function(){
    	    $('html, body').stop().animate({scrollTop: 0}, 500, 'linear');
    	    return false;
    	});

       scrollToTopButton();

    	// Fade in/out Scroll To Top button after 500px
    	function scrollToTopButton() {
    	    var button  = $('.scrollToTop'), 
    	        view = $(window),
    	        timeoutKey = -1;

    	    // page load
    	    if (view.scrollTop() > 500) {
    	        button.fadeIn();
    	    }
    	    else {
    	        button.fadeOut();
    	    }

    	    $(document).on('scroll', function() {
    	        if(timeoutKey) {
    	            window.clearTimeout(timeoutKey);
    	        }

    	        timeoutKey = window.setTimeout(function(){
    	            if (view.scrollTop() > 500) {
    	                button.fadeIn();
    	            }
    	            else {
    	                button.fadeOut();
    	            }
    	        }, 100);
    	    });
    	}
	    
    }

}

class UtilityNav {
  static main() {
    $(window).on("load", function () {
      // Toggle Utility Dropdown
      jQuery(document).on('click', '.utility-links .toggle-btn', function (e) {
        e.preventDefault();
        openUtilityItem($(this));
      });

      // Toggle WCAG Accessibility
      jQuery(document).on('click', '.utility-links .accessibility-toggle', function (e) {
        e.preventDefault();
        toggleAccessibility($(this));
      });

      jQuery(window).on("load", function () {
        $("#google_translate_element select").find("option").not("[data-translated='true']").each(function () {
          translateTranslations($(this));
        });


        jQuery("#google_translate_element select").on("DOMSubtreeModified", function () {
          $(this).find("option").not("[data-translated='true']").each(function () {
            translateTranslations($(this));
          });
        });
      });
    });

    // Close Utility Dropdown
    $(document).on('click', '.utility-nav .dropdown .close-btn', function (e) {
      e.preventDefault();
      const dropdown = $(this).closest('.dropdown');
      closeUtilityItem(dropdown);
    });

    // Close Utility Dropdown if overlay clicked
    $(document).on('click', '.utility-nav .dropdown .overlay', function (e) {
      e.preventDefault();
      const dropdown = $(this).closest('.dropdown');
      closeUtilityItem(dropdown);
    });

    function translateTranslations(optionElement) {
      if ($(optionElement).attr("data-translated") == "true") {
        return;
      } else {
        $(optionElement).attr("data-translated", "true");
      }

      switch ($(optionElement).val()) {
        case "af": if ($(optionElement).html() != "Afrikaans") { $(optionElement).html("Afrikaans"); } break;
        case "sq": if ($(optionElement).html() != "Shqip (Albanian)") { $(optionElement).html("Shqip (Albanian)"); } break;
        case "am": if ($(optionElement).html() != "አማርኛ (Amharic)") { $(optionElement).html("አማርኛ (Amharic)"); } break;
        case "ar": if ($(optionElement).html() != "العربية (Arabic)") { $(optionElement).html("العربية (Arabic)"); } break;
        case "hy": if ($(optionElement).html() != "Հայերեն (Armenian)") { $(optionElement).html("Հայերեն (Armenian)"); } break;
        case "az": if ($(optionElement).html() != "تۆرکجه (Azerbaijani)") { $(optionElement).html("تۆرکجه (Azerbaijani)"); } break;
        case "eu": if ($(optionElement).html() != "euskera (Basque)") { $(optionElement).html("euskera (Basque)"); } break;
        case "be": if ($(optionElement).html() != "беларуская мова (Belarusian)") { $(optionElement).html("беларуская мова (Belarusian)"); } break;
        case "bn": if ($(optionElement).html() != "বাংলা (Bengali)") { $(optionElement).html("বাংলা (Bengali)"); } break;
        case "bs": if ($(optionElement).html() != "bosanski jezik (Bosnian)") { $(optionElement).html("bosanski jezik (Bosnian)"); } break;
        case "bg": if ($(optionElement).html() != "български език (Bulgarian)") { $(optionElement).html("български език (Bulgarian)"); } break;
        case "ca": if ($(optionElement).html() != "català (Catalan)") { $(optionElement).html("català (Catalan)"); } break;
        case "ceb": if ($(optionElement).html() != "Sugbuanon (Cebuano)") { $(optionElement).html("Sugbuanon (Cebuano)"); } break;
        case "ny": if ($(optionElement).html() != "chiCheŵa (Chichewa)") { $(optionElement).html("chiCheŵa (Chichewa)"); } break;
        case "zh-CN": if ($(optionElement).html() != "简体中文 (Chinese: Simplified)") { $(optionElement).html("简体中文 (Chinese: Simplified)"); } break;
        case "zh-TW": if ($(optionElement).html() != "中國傳統的 (Chinese: Traditional)") { $(optionElement).html("中國傳統的 (Chinese: Traditional)"); } break;
        case "co": if ($(optionElement).html() != "corsu (Corsican)") { $(optionElement).html("corsu (Corsican)"); } break;
        case "hr": if ($(optionElement).html() != "hrvatski jezik (Croatian)") { $(optionElement).html("hrvatski jezik (Croatian)"); } break;
        case "nl": if ($(optionElement).html() != "Nederlands (Dutch)") { $(optionElement).html("Nederlands (Dutch)"); } break;
        case "eo": if ($(optionElement).html() != "Esperanto") { $(optionElement).html("Esperanto"); } break;
        case "et": if ($(optionElement).html() != "eesti (Estonian)") { $(optionElement).html("eesti (Estonian)"); } break;
        case "tl": if ($(optionElement).html() != "Wikang Tagalog (Tagalog)") { $(optionElement).html("Wikang Tagalog (Tagalog)"); } break;
        case "fi": if ($(optionElement).html() != "suomi (Finnish)") { $(optionElement).html("suomi (Finnish)"); } break;
        case "fr": if ($(optionElement).html() != "français (French)") { $(optionElement).html("français (French)"); } break;
        case "fy": if ($(optionElement).html() != "Frysk (Western Frisian)") { $(optionElement).html("Frysk (Western Frisian)"); } break;
        case "gl": if ($(optionElement).html() != "Galego (Galician)") { $(optionElement).html("Galego (Galician)"); } break;
        case "ka": if ($(optionElement).html() != "ქართული (Georgian)") { $(optionElement).html("ქართული (Georgian)"); } break;
        case "de": if ($(optionElement).html() != "Deutsch (German)") { $(optionElement).html("Deutsch (German)"); } break;
        case "el": if ($(optionElement).html() != "ελληνικά (Greek)") { $(optionElement).html("ελληνικά (Greek)"); } break;
        case "gu": if ($(optionElement).html() != "ગુજરાતી (Gujarati)") { $(optionElement).html("ગુજરાતી (Gujarati)"); } break;
        case "ht": if ($(optionElement).html() != "Kreyòl ayisyen (Hatian Creole)") { $(optionElement).html("Kreyòl ayisyen (Hatian Creole)"); } break;
        case "ha": if ($(optionElement).html() != "هَوُسَ (Hausa)") { $(optionElement).html("هَوُسَ (Hausa)"); } break;
        case "haw": if ($(optionElement).html() != "Ōlelo Hawaiʻi (Hawaiian)") { $(optionElement).html("Ōlelo Hawaiʻi (Hawaiian)"); } break;
        case "iw": if ($(optionElement).html() != "עברית (Hebrew)") { $(optionElement).html("עברית (Hebrew)"); } break;
        case "hi": if ($(optionElement).html() != "हिन्दी, हिंदी (Hindi)") { $(optionElement).html("हिन्दी, हिंदी (Hindi)"); } break;
        case "hmn": if ($(optionElement).html() != "Hmong") { $(optionElement).html("Hmong"); } break;
        case "hu": if ($(optionElement).html() != "magyar (Hungarian)") { $(optionElement).html("magyar (Hungarian)"); } break;
        case "is": if ($(optionElement).html() != "Íslenska (Icelandic)") { $(optionElement).html("Íslenska (Icelandic)"); } break;
        case "ig": if ($(optionElement).html() != "Asụsụ Igbo (Igbo)") { $(optionElement).html("Asụsụ Igbo (Igbo)"); } break;
        case "id": if ($(optionElement).html() != "Bahasa Indonesia (Indonesian)") { $(optionElement).html("Bahasa Indonesia (Indonesian)"); } break;
        case "ga": if ($(optionElement).html() != "Gaeilge (Irish)") { $(optionElement).html("Gaeilge (Irish)"); } break;
        case "it": if ($(optionElement).html() != "Italiano (Italian)") { $(optionElement).html("Italiano (Italian)"); } break;
        case "ja": if ($(optionElement).html() != "日本語 (にほんご) (Japanese)") { $(optionElement).html("日本語 (にほんご) (Japanese)"); } break;
        case "jw": if ($(optionElement).html() != "ꦧꦱꦗꦮ (Javanese)") { $(optionElement).html("ꦧꦱꦗꦮ (Javanese)"); } break;
        case "kn": if ($(optionElement).html() != "ಕನ್ನಡ (Kannada)") { $(optionElement).html("ಕನ್ನಡ (Kannada)"); } break;
        case "kk": if ($(optionElement).html() != "қазақ тілі (Kazakh)") { $(optionElement).html("қазақ тілі (Kazakh)"); } break;
        case "km": if ($(optionElement).html() != "ខ្មែរ (Central Khmer)") { $(optionElement).html("ខ្មែរ (Central Khmer)"); } break;
        case "rw": if ($(optionElement).html() != "Ikinyarwanda (Kinyarwanda)") { $(optionElement).html("Ikinyarwanda (Kinyarwanda)"); } break;
        case "ko": if ($(optionElement).html() != "한국어 (Korean)") { $(optionElement).html("한국어 (Korean)"); } break;
        case "ku": if ($(optionElement).html() != "Kurdî (Kurdish)") { $(optionElement).html("Kurdî (Kurdish)"); } break;
        case "ky": if ($(optionElement).html() != "Кыргызча (Kirghiz)") { $(optionElement).html("Кыргызча (Kirghiz)"); } break;
        case "lo": if ($(optionElement).html() != "ພາສາລາວ (Lao)") { $(optionElement).html("ພາສາລາວ (Lao)"); } break;
        case "la": if ($(optionElement).html() != "latine (Latin)") { $(optionElement).html("latine (Latin)"); } break;
        case "lv": if ($(optionElement).html() != "latviešu valoda (Latvian)") { $(optionElement).html("latviešu valoda (Latvian)"); } break;
        case "lt": if ($(optionElement).html() != "lietuvių kalba (Lithuanian)") { $(optionElement).html("lietuvių kalba (Lithuanian)"); } break;
        case "lb": if ($(optionElement).html() != "Lëtzebuergesch (Luxembourgish)") { $(optionElement).html("Lëtzebuergesch (Luxembourgish)"); } break;
        case "mk": if ($(optionElement).html() != "македонски јазик (Macedonian)") { $(optionElement).html("македонски јазик (Macedonian)"); } break;
        case "mg": if ($(optionElement).html() != "fiteny malagasy (Malagasy)") { $(optionElement).html("fiteny malagasy (Malagasy)"); } break;
        case "ms": if ($(optionElement).html() != "Bahasa Melayu (Malay)") { $(optionElement).html("Bahasa Melayu (Malay)"); } break;
        case "ml": if ($(optionElement).html() != "മലയാളം (Malayalam)") { $(optionElement).html("മലയാളം (Malayalam)"); } break;
        case "mt": if ($(optionElement).html() != "Malti (Maltese)") { $(optionElement).html("Malti (Maltese)"); } break;
        case "mi": if ($(optionElement).html() != "te reo Māori (Māori)") { $(optionElement).html("te reo Māori (Māori)"); } break;
        case "mr": if ($(optionElement).html() != "मराठी (Marathi)") { $(optionElement).html("मराठी (Marathi)"); } break;
        case "mn": if ($(optionElement).html() != "Монгол хэл (Mongolian)") { $(optionElement).html("Монгол хэл (Mongolian)"); } break;
        case "my": if ($(optionElement).html() != "ဗမာစာ (Burmese)") { $(optionElement).html("ဗမာစာ (Burmese)"); } break;
        case "ne": if ($(optionElement).html() != "नेपाली (Nepali)") { $(optionElement).html("नेपाली (Nepali)"); } break;
        case "no": if ($(optionElement).html() != "Norsk (Norwegian)") { $(optionElement).html("Norsk (Norwegian)"); } break;
        case "or": if ($(optionElement).html() != "ଓଡ଼ିଆ (Oriya)") { $(optionElement).html("ଓଡ଼ିଆ (Oriya)"); } break;
        case "ps": if ($(optionElement).html() != "پښتو (Pashto)") { $(optionElement).html("پښتو (Pashto)"); } break;
        case "fa": if ($(optionElement).html() != "فارسی (Persian)") { $(optionElement).html("فارسی (Persian)"); } break;
        case "pl": if ($(optionElement).html() != "język polski (Polish)") { $(optionElement).html("język polski (Polish)"); } break;
        case "pt": if ($(optionElement).html() != "Português (Portuguese)") { $(optionElement).html("Português (Portuguese)"); } break;
        case "pa": if ($(optionElement).html() != "ਪੰਜਾਬੀ (Punjabi)") { $(optionElement).html("ਪੰਜਾਬੀ (Punjabi)"); } break;
        case "ro": if ($(optionElement).html() != "Română (Romanian)") { $(optionElement).html("Română (Romanian)"); } break;
        case "ru": if ($(optionElement).html() != "русский (Russian)") { $(optionElement).html("русский (Russian)"); } break;
        case "sm": if ($(optionElement).html() != "gagana fa'a Samoa (Samoan)") { $(optionElement).html("gagana fa'a Samoa (Samoan)"); } break;
        case "gd": if ($(optionElement).html() != "Gàidhlig (Gaelic)") { $(optionElement).html("Gàidhlig (Gaelic)"); } break;
        case "sr": if ($(optionElement).html() != "српски језик (Serbian)") { $(optionElement).html("српски језик (Serbian)"); } break;
        case "st": if ($(optionElement).html() != "Sesotho (Southern Sotho)") { $(optionElement).html("Sesotho (Southern Sotho)"); } break;
        case "sn": if ($(optionElement).html() != "chiShona (Shona)") { $(optionElement).html("chiShona (Shona)"); } break;
        case "sd": if ($(optionElement).html() != "सिन्धी (Sindhi)") { $(optionElement).html("सिन्धी (Sindhi)"); } break;
        case "si": if ($(optionElement).html() != "සිංහල (Sinhala)") { $(optionElement).html("සිංහල (Sinhala)"); } break;
        case "sk": if ($(optionElement).html() != "Slovenčina (Slovak)") { $(optionElement).html("Slovenčina (Slovak)"); } break;
        case "sl": if ($(optionElement).html() != "Slovenski jezik (Slovenian)") { $(optionElement).html("Slovenski jezik (Slovenian)"); } break;
        case "so": if ($(optionElement).html() != "Soomaaliga (Somali)") { $(optionElement).html("Soomaaliga (Somali)"); } break;
        case "es": if ($(optionElement).html() != "Español (Spanish)") { $(optionElement).html("Español (Spanish)"); } break;
        case "su": if ($(optionElement).html() != "Basa Sunda (Sudanese)") { $(optionElement).html("Basa Sunda (Sudanese)"); } break;
        case "sw": if ($(optionElement).html() != "Kiswahili (Swahili)") { $(optionElement).html("Kiswahili (Swahili)"); } break;
        case "sv": if ($(optionElement).html() != "Svenska (Swedish)") { $(optionElement).html("Svenska (Swedish)"); } break;
        case "tg": if ($(optionElement).html() != "тоҷикӣ (Tajik)") { $(optionElement).html("тоҷикӣ (Tajik)"); } break;
        case "ta": if ($(optionElement).html() != "தமிழ் (Tamil)") { $(optionElement).html("தமிழ் (Tamil)"); } break;
        case "tt": if ($(optionElement).html() != "татар теле (Tatar)") { $(optionElement).html("татар теле (Tatar)"); } break;
        case "te": if ($(optionElement).html() != "తెలుగు (Telugu)") { $(optionElement).html("తెలుగు (Telugu)"); } break;
        case "th": if ($(optionElement).html() != "ไทย (Thai)") { $(optionElement).html("ไทย (Thai)"); } break;
        case "tr": if ($(optionElement).html() != "Türkçe (Turkish)") { $(optionElement).html("Türkçe (Turkish)"); } break;
        case "tk": if ($(optionElement).html() != "Türkmen (Turkmen)") { $(optionElement).html("Türkmen (Turkmen)"); } break;
        case "uk": if ($(optionElement).html() != "Українська (Ukrainian)") { $(optionElement).html("Українська (Ukrainian)"); } break;
        case "ur": if ($(optionElement).html() != "اردو (Urdu)") { $(optionElement).html("اردو (Urdu)"); } break;
        case "ug": if ($(optionElement).html() != "ئۇيغۇرچە (Uighur)") { $(optionElement).html("ئۇيغۇرچە (Uighur)"); } break;
        case "uz": if ($(optionElement).html() != "Oʻzbek (Uzbek)") { $(optionElement).html("Oʻzbek (Uzbek)"); } break;
        case "vi": if ($(optionElement).html() != "Tiếng Việt (Vietnamese)") { $(optionElement).html("Tiếng Việt (Vietnamese)"); } break;
        case "cy": if ($(optionElement).html() != "Cymraeg (Welsh)") { $(optionElement).html("Cymraeg (Welsh)"); } break;
        case "xh": if ($(optionElement).html() != "isiXhosa (Xhosa)") { $(optionElement).html("isiXhosa (Xhosa)"); } break;
        case "yi": if ($(optionElement).html() != "ייִדיש (Yiddish)") { $(optionElement).html("ייִדיש (Yiddish)"); } break;
        case "yo": if ($(optionElement).html() != "Yorùbá (Yoruba)") { $(optionElement).html("Yorùbá (Yoruba)"); } break;
        case "zu": if ($(optionElement).html() != "isiZulu (Zulu)") { $(optionElement).html("isiZulu (Zulu)"); } break;
        case "en": if ($(optionElement).html() != "English") { $(optionElement).html("English"); } break;
        case "cs": if ($(optionElement).html() != "čeština (Czech)") { $(optionElement).html("čeština (Czech)"); } break;
        case "da": if ($(optionElement).html() != "dansk (Danish)") { $(optionElement).html("dansk (Danish)"); } break;
      }
    }

    // fade out links, slide clicked item to left, then slide dropdown
    function openUtilityItem(clickedEle) {
      const id = clickedEle.data('id');
      const clickedItem = clickedEle.closest('.item');

      if (id == "userway") {
        $(".uai").each(function () {
          $(this).trigger("click");
        });
      } else {
        $('.utility-links .item').not(clickedItem).fadeOut(300, function () {
          $('.utility-nav').addClass('open');

          setTimeout(function () {
            $('.utility-nav .dropdown[data-id="' + id + '"]').addClass('open').slideDown(1000, function () {
              // calculate utilty nav height and start overlay after it
              // const navHeight = $('.utility-nav').outerHeight(true);
              $('.utility-nav .overlay').css('top', 0).fadeIn(500);
            });
          }, 500);
        });
      }

      clickedEle.blur();
    }

    // The following enables or disables WCAG AAA on page load, depending on user's current selection
    $(window).on("load", function () {
      var accessibilityMode = localStorage.getItem("accessibility_mode");

      if (accessibilityMode == "true") {
        $("body").addClass("accessibility");
      } else {
        $("body").removeClass("accessibility");
      }
    });

    // Close button clicked inside Utility dropdown
    function closeUtilityItem(dropdown) {

      dropdown.find('.overlay').fadeOut(300, function () {
        dropdown.removeClass('open').slideUp(1000, function () {
          $('.utility-links .item').fadeIn(300, function () {
            $('.utility-nav').removeClass('open');
          });
        });
      });
    }

    function toggleAccessibility(clickedEle) {
      const id = clickedEle.data('id');
      const clickedItem = clickedEle.closest('.item');

      if ($("body").hasClass("accessibility")) {
        $("body").removeClass("accessibility");
        localStorage.setItem("accessibility_mode", "false");
      } else {
        $("body").addClass("accessibility");
        localStorage.setItem("accessibility_mode", "true");
      }
    }
  }
}

class Menu {
  static main() {
    menuInit();

    // Mobile Menu Toggle clicked
    $(window).on("load", function () {
      // Mobile menu mouse/touch funcitonality
      $(".toggle-menu.open-menu").off('click').on('click', function (e) {
        e.stopPropagation();
        
        if (!$('body').hasClass('nav-open')) {
          OpenMobileMenu();
        }
      });

      $(".toggle-menu.close-menu").off('click').on('click', function (e) {
        e.stopPropagation();

        if ($('body').hasClass('nav-open')) {          
          CloseMobileMenu();
        }
      });

      $(".toggle-menu.open-menu").off('keydown').on('keydown', function (e) {
        if (e.which == 13 && !$('body').hasClass('nav-open')) {
          OpenMobileMenu();
        }
      });

      $(".toggle-menu.close-menu").off('keydown').on('keydown', function (e) {
        if (e.which == 13 && $('body').hasClass('nav-open')) {
          CloseMobileMenu();
        }
      });

      // On focus in with keyboard, open menu
      $('.mobile-menu-bar').off('focusin').on('focusin', function (e) {
        var browserWidth = $(window).width();

        // Desktop Menu
        if (browserWidth < 992 && !$('body').hasClass('nav-open')) {
          OpenMobileMenu();
        }
      });

      // On pressing the escape button anywhere on the page, close menu
      $('body').on('keydown').on('keydown', function (e) {
        var browserWidth = $(window).width();

        // Desktop Menu
        if (e.which == 27 && browserWidth < 992 && $('body').hasClass('nav-open')) {
            CloseMobileMenu();
        }
      });

      // On focus out with keyboard, close menu if new target is not a child of the menu
      $(".mobile-menu-bar").off("focusout").on("focusout", function (e) {
        var menuItem = $(e.originalEvent.relatedTarget);
        var menuParent = $(e.originalEvent.relatedTarget).closest(".mobile-menu-bar");

        if (!(typeof(menuItem) == "undefined" || menuItem.length == 0) && (typeof(menuParent) == "undefined" || menuParent.length == 0)) {
          console.log("onfocusout");

          CloseMobileMenu();
        }
      });
    });

    // if window resized, reset menu
    $(window).resize(function () {
      menuHandler();
    });

    // when document is loaded, reset menu
    $(document).ready(function () {
      menuHandler();
    });

    // Add class "hasUL" for items with submenus, and add arrows 
    function menuInit() {
      // Add Tier's to each UL to assist in any stsyling
      $('.main-menu').children('ul').addClass('tier1').children('li').addClass('tier1');
      $('.main-menu ul.tier1').children('li').children('ul').addClass('tier2').children('li').addClass('tier2');

      // loop through and add class/arrows
      $('.main-menu li').each(function () {
        if ($(this).children('ul').length) {
          $(this).addClass('hasUL');
        }
        
        // tier1 arrows get tabindex for mobile focus
        if ($(this).hasClass('tier1')) {
          // add arrow to every menu item
          let text = $(this).children('a').html();
          $(this).children('a').attr("aria-label", $(this).children('a').text());

          if (text.indexOf('fa-chevron-right') == -1) {
            text = "<span>" + text + '</span> <i class="arrow fas fa-chevron-right" tabindex="0" aria-label="Toggle sub-menu expansion" role="button"></i>';
          }

          $(this).children('a').html(text);
        }
        // tier2 arrows are pure decorative
        else {
          // add arrow to every menu item and set explicit aria label to prevent duplicate read
          let text = $(this).children('a').html();
          $(this).children('a').attr("aria-label", $(this).children('a').text());

          if (text.indexOf('fa-chevron-right') == -1) {
            text = "<span>" + text + '</span> <i class="arrow fas fa-chevron-right" role="presentation"></i>';
          }

          $(this).children('a').html(text);
        }
      });

      // Set footer menu heights to be same height
      var stateLinks = $("footer .state-links").first();
      var agencyLinks = $("footer .agency-links").first();

      if (stateLinks.height() > agencyLinks.height()) {
        agencyLinks.height(stateLinks.height());
      } else if (stateLinks.height() < agencyLinks.height()) {
        stateLinks.height(agencyLinks.height());
      }
    }

    // Opens Mobile Menu
    function OpenMobileMenu() {
      menuHandler(); // rebind menu
      $('body').addClass('nav-open');

      CalculateMenuHeight();
    }

    // calculate header contents, and make menu 100vh - that total 
    function CalculateMenuHeight() {
      const utilHeight = $('.site-header .utility-nav').outerHeight(true);
      let alertHeight = 0;

      $('.site-header .alert').each(function () {
        alertHeight = alertHeight + $(this).outerHeight(true);
      });

      const brandHeight = $('.site-header .brand-row').outerHeight(true);
      const toggleMenuHeight = $('.toggle-menu').outerHeight(true);
      const HeaderHeight = utilHeight + alertHeight + brandHeight + toggleMenuHeight;
      const menuHeight = 'calc(100vh - ' + HeaderHeight + 'px)';
      $('.main-menu').css('height', menuHeight);
    }

    // Closes Mobile Menu
    function CloseMobileMenu() {
      UnbindMenu();
      $('body').removeClass('nav-open');
      
      var focusable = $(".navigation-close-focus[tabindex]");
      
      if (focusable != null && focusable.length > 0) {
        $(focusable[0]).focus();
      }
    }

    // Bind Desktop & Mobile Menu functionality
    function menuHandler() {
      const browserWidth = $(window).width();

      // Desktop Menu
      if (browserWidth >= 992) {
        // unbind desktop menu first
        $(".main-menu > ul > li").off("mouseenter");
        $(".main-menu > ul > li").off("mouseleave");

        // If the window is resized to desktop breakpoint, ensure the mobile menu is closed.
        if ($('body').hasClass('nav-open')) {
          CloseMobileMenu();
        }

        // bind hovers
        $(".main-menu > ul > li").on({
          mouseenter: function () {
            $(this).addClass('open').find('ul').stop().slideDown(300);

            var menuElement = $(this).find('ul');
            $(menuElement).removeClass('inverted');
            var docViewLeft = $(window).scrollLeft();
            var elemOffset = $(menuElement).offset();
            var elemLeft = 0;

            if (typeof elemOffset != 'undefined') {
              elemLeft = elemOffset.left;
            }

            var elemRight = elemLeft + $(menuElement).width();

            if (elemRight > $(window).width()) {
              $(menuElement).addClass('inverted');
            } else {
              $(menuElement).removeClass('inverted');
            }
          },
          mouseleave: function () {
            $(this).removeClass('open').find('ul').stop().slideUp(300);
          }
        });

        $(document).off('focus', '.main-menu > ul > li > a').on('focus', '.main-menu > ul > li > a', function () {
          $(this).parents('li').addClass('open').find('ul').stop().slideDown(300);
          $(this).parents('li').siblings().removeClass('open').find('ul').stop().slideUp(300);
        });
      }
      // Mobile Menu
      else {
        $(".main-menu .arrow").off('click').on('click', function (e) {
          e.preventDefault();
          e.stopPropagation();

          if (!$(this).hasClass("disabled")) {
            if ($(this).hasClass("fa-chevron-right")) {
              $(this)
                .removeClass("fa-chevron-right")
                .addClass('fa-chevron-down')
                .addClass('disabled')
                .closest('li')
                .addClass('open')
                .find('ul')
                .slideToggle(300, function() {
                    $(this)
                        .closest(".menu-item")
                        .find(".arrow")
                        .removeClass('disabled');
                });
            } else if ($(this).hasClass("fa-chevron-down")) {
              $(this)
                .removeClass("fa-chevron-down")
                .addClass('fa-chevron-right')
                .addClass('disabled')
                .closest('li')
                .removeClass('open')
                .find('ul')
                .slideToggle(300, function() {
                    $(this)
                        .closest(".menu-item")
                        .find(".arrow")
                        .removeClass('disabled');
                });
            }
          }
        });

        $(".main-menu .arrow").off('focusin').on('focusin', function (e) {
          e.preventDefault();
          e.stopPropagation();
          
          var menuItem = $(e.originalEvent.relatedTarget);

          if (!(typeof(menuItem) == "undefined" || menuItem.length == 0) && $(this).hasClass("fa-chevron-right") && !$(this).hasClass("disabled")) {
            $(this)
              .removeClass("fa-chevron-right")
              .addClass('fa-chevron-down')
              .addClass('disabled')
              .closest('li')
              .addClass('open')
              .find('ul')
              .slideToggle(300, function() {
                  $(this)
                      .closest(".menu-item")
                      .find(".arrow")
                      .removeClass('disabled');
              });
          }
        });

        $('.main-menu .arrow').off('keydown').on("keydown", function (e) {
          if (e.which == 13 && !$(this).hasClass("disabled"))
            $(this)
              .toggleClass('fa-chevron-right fa-chevron-down')
              .addClass('disabled')
              .closest('li')
              .toggleClass('open')
              .find('ul')
              .slideToggle(300, function() {
                  $(this)
                      .closest(".menu-item")
                      .find(".arrow")
                      .removeClass('disabled');
              });
        });
      }
    } // end menuHandler()

    // unbind menu so you don't get duplicate bindings
    function UnbindMenu() {
      // unbind desktop menu
      $(".main-menu > ul > li").off("mouseenter");
      $(".main-menu > ul > li").off("mouseleave");
      $('.main-menu > ul > li > a').off('focusin');
      $(document).off('focus', '.main-menu > ul > li > a');

      // unbind mobile menu
      $('.main-menu .arrow').off('click');
      $('.main-menu .arrow').off('keydown');
    }
  }
}

class AffiliateLogos {
  static main() {
    // Initialize Slick for affiliate logos
    $(document).ready(function () {
      if ($.isFunction($.fn.owlCarousel)) {
        $('.owl-affiliate-logos .logo-slider').owlCarousel({
          loop: true,
          nav: true,
          center: false,
          items: 4,
          dotsEach: 1,
          checkVisible: false,
          responsive: {
            991: {
              items: 4
            },
            568: {
              items: 2,
              dots: false
            },
            0: {
              items: 1,
              dots: false
            }
          },
          onInitialized: initializeEvent,
          onChange: changeEvent
        });
      }

      function initializeEvent(event) {
        // The following splits owl carousel navigation into separate elements for proper tabbing order
        $(".owl-nav").each(function () {
          var prevButton = $(this).find(".owl-prev");
          var nextButton = $(this).find(".owl-next");

          if (prevButton && prevButton.length > 0 && nextButton && nextButton.length > 0) {
            $(prevButton).attr("aria-label", "Button to show previous item in carousel display.");

            $(nextButton).attr("aria-label", "Button to show next item in carousel display.");

            var prevNav = $("<div></div>").addClass("owl-nav");

            $(this).closest(".owl-carousel").prepend($(prevNav));

            $(prevNav).append($(prevButton));
          }
        });

        // The following sets aria labels on carousel buttons for better screen reader accessibility
        $(".owl-carousel").each(function () {
          var buttons = $(this).find("button.owl-dot");
          var tiles = $(this).find(".owl-item:not(.cloned) a");

          for (var i = 0; i < buttons.length && i < tiles.length; i++) {
            $(buttons[i]).attr("aria-label", "Focus button for " + $(tiles[i]).attr("aria-label") + " carousel item.");
          }
        });

        // The following sets the tabindex for all "true" carousel items, preventing duplicates with cloned carousel item entries
        $(".owl-item:not(.cloned) a").attr("tabindex", 0);
      }

      function changeEvent(event) {
        // The following sets aria labels on carousel buttons for better screen reader accessibility
        $(".owl-carousel").each(function () {
          var buttons = $(this).find("button.owl-dot");
          var tiles = $(this).find(".owl-item:not(.cloned) a");

          for (var i = 0; i < buttons.length && i < tiles.length; i++) {
            $(buttons[i]).attr("aria-label", "Focus button for " + $(tiles[i]).attr("aria-label") + " carousel item.");
          }
        });
      }
    });
  }
}

class ResponsiveTabs {
  static main() {
    $(window).on("load", function () {
      jQuery('.responsive-tabs').each(function () {
        $(this).responsiveTabs({
          startCollapsed: 'accordion',
          setHash: true,
          rotate: false,
          animation: 'slide'
        });
      });
    });
  }
}

class Faq {
  static main() {
    $(window).on("load", function () {
      if ($('.faqs').length) {
        $(document).on('click', '.faqs .faq-questions a', function (e) {
          e.preventDefault();

          $('html, body').animate({ scrollTop: $($(this).attr('href')).offset().top, }, 1500, 'linear');
        });
      }
    });
  }
}

class Chatbot {
  static main() {
    // Click - Toggle chatbox size
    $(window).on("load", function () {
      jQuery('.expansion-button').on('click', function () {
        $(".chat-widget-container").addClass('open');
        $("#chat-widget-container").contents().find(".bot-button").click();
      });

      jQuery('.close-button').on('click', function () {
        $(".chat-widget-container").addClass('closed');
        $(".chat-widget-container").removeClass('open');
      });

      jQuery('.minimize-button').on('click', function () {
        $("#chat-widget-container").contents().find(".bot-chat-window-dismiss").click();
        $(".chat-widget-container").removeClass('open');
      });
    });
  }
}

class BlogCategoryListing {
  static main() {
    // Click - Toggle category List
    $(window).on("load", function () {
      jQuery(document).on('click', '.blog-category-listing .headline', function () {
        $(this).toggleClass('open').siblings('.category-list').slideToggle(300);
      });

      // if Enter key was pressed with keyboard, toggle category list as well
      jQuery('.blog-category-listing .headline').on('keypress', function (e) {
        if (e.which == 13) {
          $(this).toggleClass('open').siblings('.category-list').slideToggle(300);
        }
      });
    });
  }
}

class HeroSlider {
  static main() {
    $(window).on("load", function () {
      $(".hero-slider").each(function (i, slider) {
        var prev = $(slider).find(".hero-slider--wrapper--arrow__previous");
        var next = $(slider).find(".hero-slider--wrapper--arrow__next");
        var items = $(slider).find(".hero-slider--wrapper--item");

        // Add button event to move to previous slide
        if (prev != null) {
          $(prev).off('click').on("click", function () {

            var prevElem = $(slider).find(".hero-slider--wrapper--item.active").prev(".hero-slider--wrapper--item");

            if (prevElem == null || prevElem.length == 0) {
              prevElem = $(slider).find(".hero-slider--wrapper--item").last(".hero-slider--wrapper--item");
            }

            if (prevElem != null) {
              $(slider).find(".hero-slider--wrapper--item").removeClass("nextOut").removeClass("prevOut");
              $(slider).find(".hero-slider--wrapper--item.active").addClass("prevOut").removeClass("active").removeClass("nextIn").removeClass("prevIn");
              $(prevElem).addClass("active prevIn");
            }
          });
        }

        // Add button event to move to next slide
        if (next != null) {
          $(next).off('click').on("click", function () {
            var nextElem = $(slider).find(".hero-slider--wrapper--item.active").next(".hero-slider--wrapper--item");

            if (nextElem == null || nextElem.length == 0) {
              nextElem = $(slider).find(".hero-slider--wrapper--item").first(".hero-slider--wrapper--item");
            }

            if (nextElem != null) {
              $(slider).find(".hero-slider--wrapper--item").removeClass("nextOut").removeClass("prevOut");
              $(slider).find(".hero-slider--wrapper--item.active").addClass("nextOut").removeClass("active").removeClass("nextIn").removeClass("prevIn");
              $(nextElem).addClass("active nextIn");
            }
          });
        }

        // If the items list has entries, set the first to active and disable the slider buttons if necessary.
        if (items != null && items.length > 0) {
          $(items[0]).addClass("active");

          if (items.length == 1) {
            if (prev != null) {
              $(prev).addClass("d-none");
            }

            if (next != null) {
              $(next).addClass("d-none");
            }
          }
        } else {
          $(slider).addClass("d-none");
        }
      });
    });
  }
}

class ImageGallery {
  static main() {
    $(window).on("load", function () {
      // Download all image sizings of a particular image
      jQuery(".media-entry-footer .download-button").on("click", function () {
        var zip = new JSZip();
        var imageName = $(this).attr("data-name");

        var LinkParent = $("#" + $(this).attr("data-download"));

        LinkParent.children().each(function () {
          zip.file($(this).html() + "_" + imageName, fetch($(this).attr("href")).then(response => response.blob()), { binary: true });
        });

        zip.generateAsync({ type: "blob" }).then(function (content) {
          saveAs(content, imageName + "_images.zip");
        });
      });
    });
  }
}

class DocumentIcons {
	static main() {
		window.applyDocumentIcons = function(documentType, icon, viewerText, applyIcon, applyViewerText) {
			var links = $(".region-content, footer").find("a");
			var body = $("main[role='main']").first();
			var footnote = $(".page-footnote").first();
			var documentCount = 0;

			for (var i = 0; i < links.length; i++) {
				var src = links[i].href;

				if (src.toUpperCase().endsWith(documentType.toUpperCase()) && links[i].innerHTML.indexOf('<span class="hidden icon-tag">') == -1) {
          documentCount++;

          if (applyIcon == 1) {
            links[i].innerHTML += '<span class="hidden icon-tag">' + documentType + '</span>';
            var buttonLink = $(links[i]);
            var chevron = buttonLink.find('.fa-chevron-right');

            if (chevron.length > 0) {
              chevron[0].outerHTML = ('<img alt="' + documentType + ' Icon" class="inline-icon" style="margin: 0px 5px;" src="' + icon + '" >') + chevron[0].outerHTML;
            } else if (buttonLink.hasClass('btn-primary')) {
              links[i].innerHTML += '&nbsp;<img alt="' + documentType + ' Icon" class="inline-icon" src="' + icon + '" >';
            } else if (buttonLink.find('img').length > 0) {
              buttonLink.addClass('document-icon-container');
              buttonLink.find('img')[0].outerHTML += '<img alt="' + documentType + ' Icon" class="inline-icon" src="' + icon + '" >';
            } else if (buttonLink.hasClass('callout')) {
              // Apply document icon to the inside of a callout block
              var calloutLinkText = $(links[i]).find('.description');
              calloutLinkText.html(calloutLinkText.html() + '&nbsp;<img alt="' + documentType + ' Icon" class="inline-icon" src="' + icon + '" >');
            } else if ($(links[i]).find('div.background').length > 0) {
              $(links[i]).find('div.background').addClass('document-icon-container');
              $(links[i]).find('div.background')[0].innerHTML += '<img alt="' + documentType + ' Icon" class="inline-icon" src="' + icon + '" >';
            }else if ($(links[i]).find('img').length > 0 || $('footer').find(buttonLink).length > 0) {
              links[i].innerHTML += '<img alt="' + documentType + ' Icon" class="ml-1 inline-icon" src="' + icon + '" >';
            } else {
              // If the found link is not a callout, apply document icon to  the outside of the link
              links[i].outerHTML += '&nbsp;<img alt="' + documentType + ' Icon" class="inline-icon" src="' + icon + '" >';
            }
          }
				}
			}

      if (documentCount > 0 && applyViewerText == 1) {
        var elem = document.createElement("div");
        viewerText = $("<textarea/>")
          .html(viewerText)
          .text();

        if (footnote) {
          if (footnote?.html()?.indexOf('<span class="hidden icon-viewer">' + documentType + 'Viewer</span>') == -1) {
            elem.innerHTML = '<span class="hidden icon-viewer">' + documentType + 'Viewer</span>' + viewerText;

            footnote[0].appendChild(elem);
          }
        } else if (body) {
          if (body?.html()?.indexOf('<span class="hidden icon-viewer">' + documentType + 'Viewer</span>') == -1) {
            elem.classList.add("container");
            elem.innerHTML = '<div class="container page-footnote no-padding"><span class="hidden icon-viewer">' + documentType + 'Viewer</span>' + viewerText + '</div>';

            body[0].append(elem);
          }
        }
      }
		};
	}
}

class HowCanWeHelpYou {
  static main() {
    $(window).on("load", function () {
      // On option change, the following either filters the entries in the second HCWHY list or directs users to the URL specified in the first list's items
      jQuery(document).on('change', '.lookingToURL', function () {
        if ($(this)[0].options[$(this)[0].options.selectedIndex].value != "") {
          document.location.href = $(this)[0].options[$(this)[0].options.selectedIndex].value;
        }
        else {
          // If there is no URL value for the first list's option, it is used to filter entries from the second list.
          var listFilter = $($(this)[0].options[$(this)[0].options.selectedIndex]).attr("data-id");
          var visibleOptions = 0;

          $(this).closest('.help-box').find('.lookingForURL').find('option').each(function () {
            if ($(this).attr("data-selector") == listFilter || $(this)[0].index == "0") {
              visibleOptions += 1;
              $(this).show();
            }
            else {
              $(this).hide();
            }
          });

          if (visibleOptions > 1) {
            $(this).closest('.help-box').find('.hcwhyList2').show();
          }
          else {
            $(this).closest('.help-box').find('.hcwhyList2').hide();
          }
        }
      });

      // Directs users to the URL specified in the second list's items
      jQuery(document).on('change', '.lookingForURL', function () {
        if ($(this)[0].options[$(this)[0].options.selectedIndex].value != "") {
          document.location.href = $(this)[0].options[$(this)[0].options.selectedIndex].value;
        }
      });

      // The following prefilters the list of available links on load.
      $(window).on("load", function () {
        $('.lookingForURL').each(function () {
          var visibleOptions = 0;

          $(this).find('option').each(function () {

            if ($(this).attr("data-selector") != "" && $(this)[0].index != "0") {
              $(this).hide();
            } else {
              visibleOptions += 1;
            }
          });

          if (visibleOptions > 1) {
            $(this).closest('.help-box').find('.hcwhyList2').show();
          }
          else {
            $(this).closest('.help-box').find('.hcwhyList2').hide();
          }
        });
      });
    });
  }
}

/*
    :: Site Specific javascript modules
*/

class CustomJS {

  static main() {
    ScrollToTop.main();
    UtilityNav.main();
    Menu.main();
    AffiliateLogos.main();
    ResponsiveTabs.main();
    Faq.main();
    BlogCategoryListing.main();
    HeroSlider.main();
    Chatbot.main();
    ImageGallery.main();
    DocumentIcons.main();
    HowCanWeHelpYou.main();
  }
}

const MainScripts = (() => {

    /*
        :: Initialize core st boilerplate modules
    */
    StCore.main({
        initialize: {
            TextResizer: true
        }
    });
    /*
        :: Initialize website/project specific modules
    */
    CustomJS.main();
    /*
        :: Initialize third party plugins
    */
    // SVG <use> polyfill for IE and Edge
    if (typeof svg4everybody == 'function') {
        svg4everybody();
    }

})();
